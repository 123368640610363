.pricingContainer {
    text-align: center;
}

.averageTime {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 50%;
    margin: 0 auto;

    display: flex;
    flex: 1;
    border-radius: 10px;
    background-color: #242424;
    justify-content: center;
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));

    color: white;
}

.averageHeader {
    font-size: 2.5rem;
    margin-top: .5rem;
}

.averageList {
    width: 80%;
    margin: 1.5rem 0;
}

.listElement {
    display: flex;
    justify-content: space-between;
}

.listText, .listTime {
    font-size: 1.5rem;
    margin-bottom: .3rem;
}


@media screen and (max-width: 768px) {
    .pricingContainer {
        margin-bottom: 1rem;
    }

    .averageTime {
        max-width: 80%;
        margin: 0 auto;
    }

    .listElement {
        display: block;
        margin: 1rem 0;

    }

    .listText, .listTime {
        margin: .3rem 0;
    }
}