.dateTime_prompt {
    font-size: 2.5em;
    margin: .5em 0;
}

.datePicker {
    align-items: center;
    text-align: center;
    max-width: 80em;
    margin: auto;
}

.available_dates {
    display: flex;
    justify-content: center;
}


.availDay, .unavailDay, .unavailDay.active, .availDay.active{
    font-size: 1.2em;
    display: inline-block;
    padding: 1em;
    margin: 1em;
    border-radius: 1em;
    outline: none;
    border: none;
    cursor: pointer;
}



.availDay, .availTime {
    background-color: rgb(255, 255, 255);
    color: black;
    border: 1px black solid;
}

.availDay.active, .availTime.active {
    background-color: black;
    color: white;
}

.unavailDay, .unavailTime {
    background-color: rgb(244,244,244);
    color: rgb(124, 124, 124);
    border: none;
}

.unavailDay.active, .unavailTime.active {
    background-color: black;
    color: white;
}



.timeSelect {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}


.unavailTime, .availTime {
    font-size: 1.3em;
    padding: .2em .5em;
    margin: .1em .3em;

    outline: none;
    cursor: pointer;
}



.unavailText {
    margin-top: 1em;
    font-size: 2em;
}

.inputForm {
    margin: 1rem auto;
    border-radius: 10px;
    background-color: #242424;
    color: #fff;
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    padding: 1em;
    max-width: 60%;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}

.inputSelect { grid-area: 1 / 1 / 2 / 2; }
.selectExtra { grid-area: 1 / 2 / 2 / 3; }
.inputName { grid-area: 2 / 1 / 3 / 2; }
.inputPhoneNumber { grid-area: 2 / 2 / 3 / 3; }
.inputEmail { grid-area: 3 / 1 / 4 / 3; }
.inputMessage { grid-area: 4 / 1 / 5 / 3; }
.inputButton { grid-area: 5 / 1 / 6 / 3; }


.inputSelect, .selectExtra, .inputName, .inputPhoneNumber, .inputEmail, .inputMessage, .inputButton, .inputService{
    border-radius: 10px;
    max-width: 100%;
    margin: .2rem .2rem;
    border: none;
    resize: none;
}
