.contact_container {
    text-align: center;
}

.contact_box {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 50%;
    margin: 2rem auto;
    padding: 2rem 0;

    display: flex;
    flex: 1;
    border-radius: 10px;
    background-color: #242424;
    justify-content: center;
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));

    color: white;
}

.contact_el {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    width: 80%;
    color: white;
    text-decoration: none;
    padding: 1rem 0;
}

.contact_ting{
    font-size: 2rem;
}

.contact_icon {
    font-size: 2rem;
    margin-right: 1rem;
}

.contact_ting_xs {
    font-size: 1rem;
}

@media screen and (max-width: 768px) {
    .contact_box {
        max-width: 80%;
        margin: 0 auto;
        margin-top: 3rem;
    }

    .contact_icon {
        display: block;
    }
}