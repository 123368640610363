.hero-container {
    background: url('images/temp-cover2.jpg') center center/cover no-repeat;
    background-color: #354445;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.hero-text-container {
    max-width: 80em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.hero-text-container > h1 {
    color: #ffffff;
    font-size: 80px;
    margin-top: 80px;
    text-align: center;
}

.hero-text-container > p {
    color: #ffffff;
    font-size: 30px;
    margin-top: 1em;
}

.hero-btns {
    margin-top: 32px;
    margin-bottom: 32px;
}


@media screen and (max-width: 960px) {
    .hero-text-container {
        max-width: 60em;
    }

    .hero-text-container > h1 {
        font-size: 50px;
        margin-top: 60px;
    }
    
    .hero-text-container > p {
        color: #ffffff;
        font-size: 25px;
    }
}

@media screen and (max-width: 768px) {
    .hero-text-container {
        max-width: 80%;
    }

    .hero-text-container > h1 {
        font-size: 40px;
        margin-top: 60px;
    }

    .hero-text-container > p {
        font-size: 1.1em;
        max-width: 90%;
    }

    .btn-mobile {
        display: block;
        text-decoration: none;
    }

}