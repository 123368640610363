.cards {
  padding: .8rem;
  background: #fff;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}


.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
  background-color: #242424;
  justify-content: center;
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
}

.cards__item__info {
  padding: 20px 30px 30px;
  color: #fff;
  text-align: center;
}

.cards__item__prompt {
  font-size: 2.2em;
  line-height: 1.2em;
  margin-bottom: .3em;
}

.cards__item__price {
  font-size: 1.5em;
  line-height: 2em;
}

.cards__item__text {
  font-size: 1.5em;
  margin-bottom: .5em;
}

@media screen and (max-width: 960px) {
  
}

@media screen and (max-width: 768px) {
  .cards__item__prompt {
    font-size: 1.75em;
    line-height: 1.2em;
    margin-bottom: .3em;
  }
  
  .cards__item__price {
    font-size: 1.3em;
    line-height: 1.5em;
  }
  
  .cards__item__text {
    font-size: 1.3em;
    margin-bottom: .5em;
  }
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}